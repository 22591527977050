import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import projectsData from '../projects/projects.json';
import hero0 from '../images/app_reveal.mp4';
import hero1 from '../images/storm_2.mp4';
import hero2 from '../images/card-signifly.jpg';
import hero3 from '../images/card-hcc.jpg';
import hero4 from '../images/card-cp.jpg';
import hero5 from '../images/card-ddl.jpg';
import hero6 from '../images/boxes.jpg';
import hero7 from '../images/zaptec_1.mp4';
import hero8 from '../images/card-tekla.jpg';
import hero9 from '../images/lp/lp-card-spotifly.jpg';
import hero10 from '../images/blurry_1.mp4';
import hero11 from '../images/card-brainy.jpg';
import hero12 from '../images/card-lost.jpg';
import hero13 from '../images/card-ferm.jpg';
import hero14 from '../images/card-7.jpg';
import hero15 from '../images/card-ooono.jpg';
import hero16 from '../images/card-yolk.jpg';

const imageMap = {
  'Original Talks': { type: 'video', src: hero0 },
  'StormGeo': { type: 'video', src: hero1 },
  'Signifly': { type: 'image', src: hero2 },
  'Healthcare Card': { type: 'image', src: hero3 },
  'Corona Passport': { type: 'image', src: hero4 },
  'Digital Drivers License': { type: 'image', src: hero5 },
  'Eupry': { type: 'image', src: hero6 },
  'Zaptec': { type: 'video', src: hero7 },
  'Tekla': { type: 'image', src: hero8 },
  'Switch': { type: 'image', src: hero9 },
  'Blurry': { type: 'video', src: hero10 },
  'Brainy': { type: 'image', src: hero11 },
  'Lost': { type: 'image', src: hero12 },
  'Ferm Living': { type: 'image', src: hero13 },
  '7-Eleven': { type: 'image', src: hero14 },
  'OOONO': { type: 'image', src: hero15 },
  'Yolk': { type: 'image', src: hero16 },
};

const GridLayout = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  return (
    <div>
      <div className="grid-wrapper">
        {projects.map((project, index) => {
          const slug = project.title
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '');

          const heroMedia = imageMap[project.title];

          return (
            <div key={index} className={`visual-project ${project.widthClass}`}>
              <Link to={`/project/${slug}`}>
                <div>
                <div className="case-content-wrapper">
                  {heroMedia.type === 'image' ? (
                    <img src={heroMedia.src} alt={`Hero for ${project.title}`} />
                  ) : (
                    <video src={heroMedia.src} autoPlay loop muted playsInline>
                      Your browser does not support the video tag.
                    </video>
                  )}
                  </div>
                  <h2>{project.title}</h2>
                  <p>{project.teaser}</p>
                  <p>{project.type}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GridLayout;
