import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Error = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
        window.scrollTo(0, 0);
        setIsFirstRender(false);
      }
  })
  return (
    <div>
    <div className="error-wrapper">
      <div className="flex">
        <div className="title-wrapper">
          <div className="left-side label-group-wrapper">
            <Link to="/work" className="grey-text left-copy tiny-label">
              Go back
            </Link>
          </div>
          <div className="right-side">
            <h1 className="move-left">
              ¯\_(ツ)_/¯
            </h1>
          </div>
        </div>
      </div>
      <div className="flex error-info">
        <div className="left-side"></div>
        <div className="right-side">
          <div className="narrow">
            <p>Nothing to see here. Unless you wanted to see this 404 page, then good on you. You found it.</p>
          </div>
        </div>
      </div>

      </div>
    </div>
  );
};

export default Error;
