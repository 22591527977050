import React, { useState } from 'react';

const Input = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (value === '') {
      setIsFocused(false);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper">
    <div className="ui-component-button">
      <button>
        Learn more
        <div className="arrow-line"></div>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 1L7 5L3 9" stroke="white" stroke-width="2"/>
</svg>

      </button>
      </div>
    </div>
  );
};

export default Input;
