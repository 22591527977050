import React from 'react';

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer-left left-side">
          <div className="overflow-hidden">
            <p className="year">2013</p>
            <p className="year year-hidden">— 2024</p>
          </div>
        </div>
        <div className="footer-right right-side">
          <div className="flex space">
            <p className="year em-dash">—</p>
              <p className="year">2024</p>
            </div>
            <div className="footer-bottom">
              <div className="links">
                <a href="https://twitter.com/martinballe">Twitter</a>
                <a href="https://www.linkedin.com/in/martin-balle-3248b8198/">LinkedIn</a>
              </div>
              <div className="outtro">
                <p>
                  Primary type is Helvetica Neue, and all indexed projects are either freelance work, or done while working at Signifly.
                  <br /><br />
                  All rights reserved © 2024.
                </p>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
