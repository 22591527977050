import React, { useState, useEffect, useRef } from 'react';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('Products');
  const selectorRef = useRef(null);

  useEffect(() => {
    const tabElements = document.querySelectorAll('.ui-tabs');
    const activeElement = Array.from(tabElements).find(
      (el) => el.textContent.trim() === activeTab
    );
    const selector = selectorRef.current;

    if (activeElement && selector) {
      const activeRect = activeElement.getBoundingClientRect();
      const parentRect = activeElement.parentElement.getBoundingClientRect();
      selector.style.width = `${activeRect.width}px`;
      selector.style.left = `${activeRect.left - parentRect.left}px`;
    }
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper ui-component-tabs">
      <div className="ui-tabs" onClick={() => handleTabClick('Products')}><span>Products</span></div>
      <div className="ui-tabs" onClick={() => handleTabClick('Solutions')}><span>Solutions</span></div>
      <div className="ui-tabs" onClick={() => handleTabClick('Resources')}><span>Resources</span></div>
      <div className="ui-tabs" onClick={() => handleTabClick('Developers')}><span>Developers</span></div>
      <div className="ui-tabs" onClick={() => handleTabClick('Pricing')}><span>Pricing</span></div>
      <div className="dynamic-selector" ref={selectorRef}></div>
    </div>
  );
};

export default Tabs;
