import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import GridLayout from '../components/GridLayout';
import ListLayout from '../components/ListLayout';

const Work = () => {
  const [currentLayout, setCurrentLayout] = useState('grid');
  const [showGridTooltip, setShowGridTooltip] = useState(false);
  const [showListTooltip, setShowListTooltip] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);

  const toggleLayout = (layout) => {
    setCurrentLayout(layout);
  };

  useEffect(() => {
    if (isFirstRender) {
        window.scrollTo(0, 0);
        setIsFirstRender(false);
      }

      const handleKeyDown = (event) => {
        if (event.shiftKey && (event.key === 'g' || event.key === 'G')) {
          toggleLayout('grid');
        } else if (event.shiftKey && (event.key === 'l' || event.key === 'L')) {
          toggleLayout('list');
        }
      };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div className="flex">

        <div className="title-wrapper">
          <div className="left-side label-group-wrapper work-group">
            <p className="grey-text large-body left-copy-wide">
              Selected projects within UI/UX Design and Brand Design.
            </p>
            </div>
          <div className="right-side">
            <h1 className="push-forward">
              Work
            </h1>
            <div className="toggle-wrapper">
              <div className={`toggle grid-toggle ${currentLayout === 'grid' ? 'active' : ''}`}
            onClick={() => setCurrentLayout('grid')}
            onMouseEnter={() => setShowGridTooltip(true)}
            onMouseLeave={() => setShowGridTooltip(false)}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="trigger-svg">
                <rect x="1.5" y="1.5" width="5" height="5" rx="1" stroke="black" stroke-width="1.25"/>
                <rect x="9.5" y="1.5" width="5" height="5" rx="1" stroke="black" stroke-width="1.25"/>
                <rect x="1.5" y="9.5" width="5" height="5" rx="1" stroke="black" stroke-width="1.25"/>
                <rect x="9.5" y="9.5" width="5" height="5" rx="1" stroke="black" stroke-width="1.25"/>
              </svg>
                {showGridTooltip && (
                  <div className="tooltip">
                    <label>
                      Grid
                    </label>
                    <span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 13H8H10C10.2761 13 10.5 12.7761 10.5 12.5V9.75C10.5 9.61193 10.6119 9.5 10.75 9.5H13.2929C13.7383 9.5 13.9614 8.96143 13.6464 8.64645L8.53033 3.53033C8.23744 3.23744 7.76256 3.23744 7.46967 3.53033L2.35355 8.64645C2.03857 8.96143 2.26165 9.5 2.70711 9.5H5.25C5.38807 9.5 5.5 9.61193 5.5 9.75V12.5C5.5 12.7761 5.72386 13 6 13Z" stroke="black" stroke-width="1.35" stroke-linecap="round"/>
                      </svg>
                    </span>
                    <span>G</span>
                  </div>
                )}
              </div>
              <div className={`toggle list-toggle ${currentLayout === 'list' ? 'active' : ''}`}
            onClick={() => setCurrentLayout('list')}
            onMouseEnter={() => setShowListTooltip(true)}
            onMouseLeave={() => setShowListTooltip(false)}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="trigger-svg">
                  <path d="M1 2H15" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1 6H15" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1 10H15" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1 14H15" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {showListTooltip && (
                  <div className="tooltip tooltip-list">
                    <label>
                      List
                    </label>
                    <span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 13H8H10C10.2761 13 10.5 12.7761 10.5 12.5V9.75C10.5 9.61193 10.6119 9.5 10.75 9.5H13.2929C13.7383 9.5 13.9614 8.96143 13.6464 8.64645L8.53033 3.53033C8.23744 3.23744 7.76256 3.23744 7.46967 3.53033L2.35355 8.64645C2.03857 8.96143 2.26165 9.5 2.70711 9.5H5.25C5.38807 9.5 5.5 9.61193 5.5 9.75V12.5C5.5 12.7761 5.72386 13 6 13Z" stroke="black" stroke-width="1.35" stroke-linecap="round"/>
                      </svg>
                    </span>
                    <span>L</span>
                  </div>
                )}
              </div>
            </div>
        </div>
      </div>
      </div>
      {currentLayout === 'grid' ? <GridLayout /> : <ListLayout />}
      <Footer />
    </div>
  );
};

export default Work;
