import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import projectsData from '../projects/projects.json';
import Toast from './Toast';

import hero0 from '../images/storm_square.jpg';
import hero1 from '../images/boxes.jpg';
import hero2 from '../images/card-yolk.jpg';
import hero3 from '../images/card-signifly.jpg';
import hero4 from '../images/card-cp.jpg';
import hero5 from '../images/card-brainy.jpg';
import hero6 from '../images/card-blurry.jpg';
import hero7 from '../images/card-hcc.jpg';
import hero8 from '../images/card-zaptec.jpg';
import hero9 from '../images/card-ferm.jpg';
import hero10 from '../images/card-ooono.jpg';
import hero11 from '../images/card-tekla.jpg';
import hero12 from '../images/card-spotifly.jpg';
import hero13 from '../images/card-ddl.jpg';
import hero14 from '../images/card-lost.jpg';
import hero15 from '../images/card-7.jpg';
import hero16 from '../images/card-yolk.jpg';
import hero17 from '../images/ot_search.png';


const imageMap = {
  'StormGeo': hero0,
  'Eupry': hero1,
  'Yolk': hero2,
  'Signifly': hero3,
  'Corona Passport': hero4,
  'Brainy': hero5,
  'Blurry': hero6,
  'Healthcare Card': hero7,
  'OOONO': hero8,
  'Tekla': hero11,
  'Ferm Living': hero9,
  'Zaptec': hero10,
  'Switch': hero12,
  'Digital Drivers License': hero13,
  'Lost': hero14,
  '7-Eleven': hero15,
  'Yolk': hero16,
  'Original Talks': hero17,
};


const CommandMenu = ({ isOpen, onClose, pages, projects }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(0); // Initialize with 0
  const inputRef = useRef(null);
  const selectedItemRef = useRef(null);
  const menuRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();
  const currentURL = location.pathname.toLowerCase();
  const navigate = useNavigate();
  const currentPage = pages.find((page) =>
    currentURL.includes(page.link.toLowerCase())
  );
  const currentPageTitle = currentPage ? currentPage.title : '';
  const currentProject = projectsData.find((project) =>
    currentURL.includes(project.title.toLowerCase())
  );
  const currentProjectTitle = currentProject ? currentProject.title : '';
  const [showCopyToast, setShowCopyToast] = useState(false); // State for displaying the copy toast
  const toastDuration = 3000;

  useEffect(() => {
    // Reset selectedItemIndex to 0 when searchQuery changes
    setSelectedItemIndex(0);
  }, [searchQuery]);

  const copyCurrentPageLink = () => {
    const currentURL = window.location.href;
    const textArea = document.createElement('textarea');
    textArea.value = currentURL;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    setShowCopyToast(true); // Display the toast

    if (inputRef.current) {
      // Set focus back to the input field after copying
      inputRef.current.focus();
    }
  };

  const handleCopyPageURL = () => {
  copyCurrentPageLink();
};


  const customCommands = [
    {
      title: 'Got a project in mind?',
      link: 'mailto:ellabnitram@gmail.com',
      icon: '/images/svg/email.svg', // Replace with the actual icon path
    },
    {
      title: 'Get Lost',
      link: 'https://lost.studio',
      icon: '/images/svg/external.svg', // Replace with the actual icon path
    },
    {
      title: 'I work at Signifly',
      link: 'https://signifly.com',
      icon: '/images/svg/external.svg', // Replace with the actual icon path
    },
    {
      title: 'Listen to some Trip Hop and Dark 90`s',
      link: 'https://open.spotify.com/playlist/5WYxmdpnhFvebAcs6FdzmU?si=319797c9fba047ed',
      icon: '/images/svg/music.svg', // Replace with the actual icon path
    },
    {
      title: 'Listen to some Old School Hip Hop',
      link: 'https://open.spotify.com/playlist/4TAIIFiNVspOPns8MWAagO?si=23b41eca48624ecf',
      icon: '/images/svg/music.svg', // Replace with the actual icon path
    },
    {
      title: 'Copy page URL',
      action: handleCopyPageURL, // This is the function to copy the URL
      icon: '/images/svg/link.svg', // Replace with the actual icon path
    },
    {
      title: 'Follow me on Twitter',
      link: 'https://twitter.com/martinballe',
      icon: '/images/svg/twitter.svg', // Replace with the actual icon path
    },
    {
      title: 'Connect with me on LinkedIn',
      link: 'https://www.linkedin.com/in/martin-balle-3248b8198/',
      icon: '/images/svg/twitter.svg', // Replace with the actual icon path
    },
    // Add more custom commands as needed
  ];

  const toggleDarkMode = () => {
    // Toggle dark mode state
    setIsDarkMode(!isDarkMode);
  };

  console.log('Command Menu isOpen:', isOpen);

  useEffect(() => {
    if (isDarkMode) {
      // Apply dark mode styles here, e.g., by adding a 'dark' class to the body or HTML element
      document.documentElement.classList.add('dark');
    } else {
      // Remove dark mode styles here, e.g., by removing the 'dark' class from the body or HTML element
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  useEffect(() => {
  // Only add the effect when the component mounts, and don't react to state changes
  const currentIsDarkMode = document.documentElement.classList.contains('dark');
  setIsDarkMode(currentIsDarkMode);
}, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (e) => {
  if (e.key === 'k' && e.metaKey) {
    e.preventDefault();
    onClose(!isOpen);
  } else if (e.key === 'Escape' && isOpen) {
    e.preventDefault();
    onClose(false);
  } else if (isOpen) {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (selectedItemIndex < filteredPages.length + filteredProjects.length + filteredCommands.length - 1) {
        setSelectedItemIndex((prevIndex) => Math.min(prevIndex + 1, filteredPages.length + filteredProjects.length + filteredCommands.length - 1));
        scrollSelectedItemIntoView();
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (selectedItemIndex > 0) {
        setSelectedItemIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        scrollSelectedItemIntoView();
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedItemIndex >= 0) {
        const selectedItem =
          selectedItemIndex < filteredPages.length
            ? filteredPages[selectedItemIndex]
            : selectedItemIndex < filteredPages.length + filteredProjects.length
            ? filteredProjects[selectedItemIndex - filteredPages.length]
            : filteredCommands[
                selectedItemIndex -
                (filteredPages.length + filteredProjects.length)
              ];

        if (selectedItem) {
          if (selectedItem.link && selectedItemRef.current) {
            navigate(selectedItem.link);
            selectedItemRef.current.click();
            onClose(false);
            setSearchQuery('');
          } else if (selectedItem.action) {
            if (selectedItem.title === 'Copy page URL') {
              // Special handling for "Copy page URL"
              selectedItem.action();
            } else {
              selectedItem.action();
              onClose(false);
              setSearchQuery('');
            }
          }
        }
      }
    }
  }
};


    // Scroll the menu to keep the selected item in view
    const scrollSelectedItemIntoView = () => {
      if (selectedItemRef.current && menuRef.current) {
        selectedItemRef.current.scrollIntoView({ block: 'nearest' });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    scrollSelectedItemIntoView(); // Scroll to the selected item when the menu opens
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose, selectedItemIndex, pages, projects, customCommands]);

  // Filter pages, projects, and commands based on search query
  const filteredPages = pages
    .filter((page) =>
      page.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((page) => page.title !== currentPageTitle);

  const filteredProjects = projectsData
    .filter((project) =>
      project.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((project) => project.title !== currentProjectTitle);
  const filteredCommands = customCommands.filter((command) =>
    command.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const showPagesLabel = filteredPages.length > 0;
  const showProjectsLabel = filteredProjects.length > 0;
  const showCommandsLabel = filteredCommands.length > 0;

  const isMenuEmpty =
    filteredPages.length === 0 &&
    filteredProjects.length === 0 &&
    filteredCommands.length === 0;

    useEffect(() => {
    // Close the modal when clicking outside
    const handleBodyClick = (e) => {
      if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        onClose(false);
        setSearchQuery('');
      }
    };

    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick);
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [isOpen, onClose]);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`command-modal-wrapper ${isOpen ? 'open' : ''}`}>
      <div className="command-modal" onClick={(e) => e.stopPropagation()}>
        <div className="command-modal-pre">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Start typing to search..."
              autoFocus
              ref={inputRef}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="divider"></div>
          </div>
          <div className="command-modal-inner" ref={menuRef}>
            {showPagesLabel && (
              <div className="menu-section">
                <p className="tiny-modal-label pages-label">Pages</p>
                <ul>
                  {filteredPages.map((page, index) => (
                    <Link to={page.link} key={index} className={selectedItemIndex === index ? 'selected' : ''}
          onClick={() => {setSelectedItemIndex(index); onClose(false); setSearchQuery('');}}
          ref={selectedItemIndex === index ? selectedItemRef : null}>
                      <li>
                        <div className="flex">
                          <div className="command-poster">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.5 7H12.5M12.5 7L6.5 1M12.5 7L6.5 13" stroke="black" stroke-width="1.5" />
                            </svg>
                          </div>
                          <p>{page.title}</p>
                        </div>
                        <div className="modal-key">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 4H11C11.5523 4 12 4.44772 12 5V9C12 9.55228 11.5523 10 11 10H4M4 10L6.5 7.5M4 10L6.5 12.5" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            )}
            {showProjectsLabel && (
              <div className="menu-section">
                <p className="tiny-modal-label projects-label">Projects</p>
                <ul>
                  {filteredProjects.map((project, index) => {
                    const slug = project.title
                      .toLowerCase()
                      .trim()
                      .replace(/\s+/g, '-')
                      .replace(/[^\w-]+/g, '');
                    const projectImage = imageMap[project.title];
                    return (
                      <div key={index}>
                        <Link to={`/project/${slug}`} className={selectedItemIndex === index + filteredPages.length ? 'selected' : ''} onClick={() => {
    setSelectedItemIndex(index + filteredPages.length);
    onClose(false); // Close the Command Menu
    setSearchQuery('');
  }}
        ref={selectedItemIndex === index + filteredPages.length ? selectedItemRef : null}>
                          <li>
                            <div className="flex">
                              <img src={projectImage} className="command-poster" alt="" />
                              <p>{project.title}</p>
                            </div>
                            <div className="modal-key">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 4H11C11.5523 4 12 4.44772 12 5V9C12 9.55228 11.5523 10 11 10H4M4 10L6.5 7.5M4 10L6.5 12.5" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </div>
                          </li>
                        </Link>
                      </div>
                    );
                  })}
                </ul>
              </div>
            )}
            {showCommandsLabel && (
              <div className="menu-section">
                <p className="tiny-modal-label commands-label">Other</p>
                <ul>
                  {filteredCommands.map((command, index) => (
                    <a href={command.link} target="_blank" key={index} className={selectedItemIndex === index + filteredPages.length + filteredProjects.length ? 'selected' : ''} ref={
            selectedItemIndex ===
            index +
              filteredPages.length +
              filteredProjects.length
              ? selectedItemRef
              : null
          } onClick={(e) => {
            e.preventDefault();
            if (command.action) {
              // Check if the command has an associated action
              command.action(); // Call the associated action
            } else {
              // If not, open the link in a new tab
              window.open(command.link, '_blank');
            }
            onClose(false); // Close the Command Menu
            setSearchQuery('');
          }}>
                      <li>
                        <div className="flex">
                          <div className="command-poster">
                            <img src={command.icon} alt="" /> {/* Use the command's icon */}
                          </div>
                          <p>{command.title}</p>
                        </div>
                        <div className="modal-key">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 4H11C11.5523 4 12 4.44772 12 5V9C12 9.55228 11.5523 10 11 10H4M4 10L6.5 7.5M4 10L6.5 12.5" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </li>
                    </a>
                  ))}
                </ul>
              </div>
            )}
            {isMenuEmpty && (
            <div className="menu-section">
              <p className="tiny-modal-label no-results-label">No results found.</p>
            </div>
          )}
          </div>
        </div>
      </div>
      {showCopyToast && (
        <Toast
          message="URL copied to clipboard"
          duration={toastDuration}
          onClose={() => setShowCopyToast(false)}
        />
      )}
    </div>
  );
};

export default CommandMenu;
