import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Import the Link component
import projectsData from '../projects/projects.json';

import hero0 from '../images/app_reveal.mp4';
import hero1 from '../images/storm_1.mp4';
import hero2 from '../images/lp/lp-signifly.jpg';
import hero3 from '../images/lp/boxes.jpg';
import hero4 from '../images/lp/lp-card-cp.jpg';
import hero5 from '../images/lp/lp-card-brainy.jpg';
import hero6 from '../images/lp/blurry_1.mp4';
import hero7 from '../images/lp/lp-card-hcc.jpg';
import hero8 from '../images/lp/zaptec_1.mp4';
import hero9 from '../images/lp/lp-card-ferm.jpg';
import hero10 from '../images/lp/lp-card-ooono.jpg';
import hero11 from '../images/lp/lp-card-tekla.jpg';
import hero12 from '../images/lp/lp-card-spotifly.jpg';
import hero13 from '../images/lp/lp-card-ddl.jpg';
import hero14 from '../images/lp/lp-card-lost.jpg';
import hero15 from '../images/lp/lp-card-nih.jpg';
import hero16 from '../images/lp/lp-card-losers.jpg';
import hero17 from '../images/lp/lp-card-7.jpg';
import hero18 from '../images/lp/lp-yolk-card.jpg';

const mediaMap = {
'Original Talks': { type: 'video', src: hero0 },
'StormGeo': { type: 'video', src: hero1 },
'Signifly': { type: 'image', src: hero2 },
'Eupry': { type: 'image', src: hero3 },
'Corona Passport': { type: 'image', src: hero4 },
'Brainy': { type: 'image', src: hero5 },
'Blurry': { type: 'video', src: hero6 },
'Healthcare Card': { type: 'image', src: hero7 },
'Zaptec': { type: 'video', src: hero8 },
'Ferm Living': { type: 'image', src: hero9 },
'OOONO': { type: 'image', src: hero10 },
'Tekla': { type: 'image', src: hero11 },
'Switch': { type: 'image', src: hero12 },
'Digital Drivers License': { type: 'image', src: hero13 },
'Lost': { type: 'image', src: hero14 },
'NIH': { type: 'image', src: hero15 },
'Losers': { type: 'image', src: hero16 },
'7-Eleven': { type: 'image', src: hero17 },
'Yolk': { type: 'image', src: hero18 },
};

const Home = () => {
  useEffect(() => {
    document.body.classList.add('body-overflow-hidden');

    return () => {
      document.body.classList.remove('body-overflow-hidden');
    };
  }, []);

  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const navigate = useNavigate();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleCommandMenuToggle = (isOpen) => {
    setMenuIsOpen(isOpen);
  };

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  const displayNextProject = useCallback(() => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  }, [projects.length]);

  const displayPreviousProject = useCallback(() => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  }, [projects.length]);

  const currentProject = projects[currentProjectIndex];

  const handleKeyDown = (event) => {

  switch (event.key) {
    case 'ArrowLeft':
      displayPreviousProject();
      break;
    case 'ArrowRight':
      displayNextProject();
      break;
      case 'Enter':
        if (event.shiftKey && currentProject) {
          event.preventDefault(); // Prevent the default behavior of "Enter" key
          navigate(`/project/${currentProject.title.toLowerCase().trim().replace(/\s+/g, '-')}`);
        }
        break;
    default:
      break;
  }

  if (menuIsOpen) {
      // Command Menu is open, handle commands inside it
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of "Enter" key
        // Handle the command menu's Enter key action
        // Add your command handling logic here
      }
    } else if (location.pathname.startsWith('/project') && event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of "Enter" key
      // Handle the project Enter key action
      navigate(`/project/${currentProject.title.toLowerCase().trim().replace(/\s+/g, '-')}`);
    }
};

  const handleStickClick = (index) => {
    // Update the current project index when a stick is clicked
    setCurrentProjectIndex(index);
  };

  const handleTouchStart = (e) => {
  let touchStartX = e.touches[0].clientX;

  const handleTouchEnd = (e) => {
    let touchEndX = e.changedTouches[0].clientX;
    let deltaX = touchStartX - touchEndX;

    if (deltaX > 30) {
      displayNextProject(); // Swipe left
    } else if (deltaX < -30) {
      displayPreviousProject(); // Swipe right
    }

    // Remove touchend event listener after handling swipe
    document.removeEventListener('touchend', handleTouchEnd);
  };

  document.addEventListener('touchend', handleTouchEnd);
};

  useEffect(() => {
    // Add event listener for keyboard navigation
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);


  let heroMedia = null;
  if (currentProject) {
    heroMedia = mediaMap[currentProject.title];
  }

  return (
    <div>
      <div className="home-wrapper" onTouchStart={handleTouchStart}>
        <div className="title-wrapper">
          <div className="left-side">
            <h1>Digital</h1>
          </div>
          <div className="right-side">
            <h1>Designer</h1>
          </div>
        </div>

        <div className="small-case-wrapper">
          <div className="left-side stretch">
          <div className="swipe-wrapper">
            <div className="sticks-wrapper">
              {/* Sticks code */}
              {projects.map((project, index) => (
                <div
                  key={project.title}
                  className={`stick stick-${index + 1} ${currentProjectIndex === index ? 'active' : ''}`}
                  onClick={() => handleStickClick(index)} // Handle stick click
                ></div>
              ))}
            </div>
              <div class="swipe">
                <span class="span1">Swipe</span>
                <span class="span2">Swipe</span>
              </div>
            </div>

            <div>
              {currentProject && (
                <div className="case-name-wrapper-small">
                  <h2>{currentProject.title}</h2>
                  <p>{currentProject.teaser}</p>
                </div>
              )}
              <div className="project-instruction">
                <span>Use
                <span className="shortcut">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.20124 7L8.20124 9.03195C8.20124 9.59341 7.53014 9.88304 7.12163 9.49788L4.96647 7.46593C4.69838 7.21316 4.69838 6.78684 4.96647 6.53407L7.12163 4.50212C7.53014 4.11696 8.20124 4.40659 8.20124 4.96805L8.20124 7Z" fill="black"/>
                  </svg>
                </span>
                <span className="shortcut">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.54876 7L5.54876 9.03195C5.54876 9.59341 6.21986 9.88304 6.62837 9.49788L8.78353 7.46593C9.05162 7.21316 9.05162 6.78684 8.78353 6.53407L6.62837 4.50212C6.21986 4.11696 5.54876 4.40659 5.54876 4.96805L5.54876 7Z" fill="black"/>
                  </svg>
                </span>
                to navigate between projects.</span>
                <span className="shortcut-break">Shift + Enter to view project.</span>
              </div>
            </div>
          </div>
          {currentProject && (
            <div className="right-side">
              <div className="case-image-small">
                <Link to={`/project/${currentProject.title.toLowerCase().trim().replace(/\s+/g, '-')}`} className="home-case-wrapper">
                {heroMedia && heroMedia.type === 'image' ? (
                  <img src={heroMedia.src} alt={`Hero for ${currentProject.title}`} />
                ) : (
                  <video src={heroMedia.src} autoPlay loop muted playsInline>
                    Your browser does not support the video tag.
                  </video>
                )}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
