import React, { useState, useRef, useEffect } from 'react';

const Graph = () => {
  // Initialize hoverData and hoverPosition with default values (first data point)
  const [hoverData, setHoverData] = useState({
    upperValue: 532,
    lowerValue: 0,
  });
  const svgRef = useRef(null); // Reference to the SVG element
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });

  // Labels on the X-axis
  const labels = ['Sep 1, 2024', 'Nov 1, 2024'];

  // Simulating 10 peaks (up and down) for both graphs, ensuring the peaks are different
  const upperGraphData = [532, 1041, 2549, 5139, 6512, 5504, 7498, 5103, 9081, 8430];
  const lowerGraphData = [0, 504, 1521, 832, 708, 212, 1214, 521, 308, 831];

  const maxYValue = 10000; // The max value for the y-axis (10K)

  // Weeks corresponding to data points
  const weeks = ['Week 36', 'Week 37', 'Week 38', 'Week 39', 'Week 40', 'Week 41', 'Week 42', 'Week 43', 'Week 44', 'Week 45'];

  useEffect(() => {
    const graphRect = svgRef.current.getBoundingClientRect();
    const graphWidth = graphRect.width;
    const graphHeight = graphRect.height;

    // Set the default position for the first data point on the graph
    const xPosition = (0 / (upperGraphData.length - 1)) * graphWidth;
    const yPosition = graphHeight - (upperGraphData[0] / maxYValue) * graphHeight;

    setHoverPosition({ x: xPosition, y: yPosition });
  }, []);

  // Linear interpolation between two points
  const interpolate = (x1, y1, x2, y2, x) => {
    return y1 + ((y2 - y1) * (x - x1)) / (x2 - x1);
  };

  const handleMouseMove = (e) => {
    const graphRect = svgRef.current.getBoundingClientRect();
    const mouseX = e.clientX - graphRect.left;
    const graphWidth = graphRect.width;
    const graphHeight = graphRect.height;

    // Normalize the mouse position to the range [0, 1]
    const normalizedMouseX = mouseX / graphWidth;

    // Determine the closest data points to the mouse position
    const exactIndex = normalizedMouseX * (upperGraphData.length - 1);
    const index1 = Math.floor(exactIndex);
    const index2 = Math.ceil(exactIndex);

    const x1 = (index1 / (upperGraphData.length - 1)) * graphWidth;
    const x2 = (index2 / (upperGraphData.length - 1)) * graphWidth;

    // Interpolate between the two y-values based on the x position
    const y1 = graphHeight - (upperGraphData[index1] / maxYValue) * graphHeight;
    const y2 = graphHeight - (upperGraphData[index2] / maxYValue) * graphHeight;
    const interpolatedY = interpolate(x1, y1, x2, y2, mouseX);

    // Set hover data with exact values from the graph data arrays
    setHoverData({
      upperValue: upperGraphData[index1], // Exact value from upperGraphData
      lowerValue: lowerGraphData[index1], // Exact value from lowerGraphData
    });
    setHoverPosition({ x: mouseX, y: interpolatedY });
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper">
      <div className="ui-component-graph" onMouseMove={handleMouseMove}>
      <div className="hover-info-box">
        <div className="line-flex">
          <div className="straight-line"></div>
          <p><b>New users:</b> {hoverData.upperValue}</p>
        </div>
        <div className="line-flex">
          <div className="dash-line"></div>
          <p><b>Churned users:</b> {hoverData.lowerValue}</p>
        </div>
      </div>
        <div className="y-axis">
          <span>10K</span>
          <span>8K</span>
          <span>6K</span>
          <span>4K</span>
          <span>2K</span>
          <span>0</span>
        </div>

        <div className="graph-area">
          <svg ref={svgRef} width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
            {/* Upper graph line */}
            <polyline
              fill="none"
              stroke="black"
              strokeWidth="0.5"
              points={upperGraphData
                .map((value, index) => {
                  const x = (index / (upperGraphData.length - 1)) * 100;
                  const y = 100 - (value / maxYValue) * 100;
                  return `${x},${y}`;
                })
                .join(' ')}
            />

            {/* Lower graph line */}
            <polyline
              fill="none"
              stroke="black"
              strokeWidth="0.5"
              opacity="0.25"
              points={lowerGraphData
                .map((value, index) => {
                  const x = (index / (lowerGraphData.length - 1)) * 100;
                  const y = 97.5 - (value / maxYValue) * 100;
                  return `${x},${y}`;
                })
                .join(' ')}
              strokeDasharray="1 0.5"
              strokeDashoffset="1"
            />
          </svg>

          {/* Hover Dot */}
          <div
            className="hover-dot"
            style={{
              position: 'absolute',
              left: `${hoverPosition.x}px`,
              top: `${hoverPosition.y}px`,
              width: '8px',
              height: '8px',
              backgroundColor: 'black',
              borderRadius: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          ></div>

          {/* Week Group */}
          {svgRef.current && (
            <div
              className="week-group"
              style={{
                position: 'absolute',
                left: `${hoverPosition.x}px`,
                top: '0',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                padding: '4px',
                borderRadius: '4px',
                fontSize: '9px',
                zIndex: '1',
                textTransform: 'uppercase',
                width: 'max-content',
                fontWeight: '500',
                border: '0.5px solid rgba(0,0,0,.1)',
              }}
            >
              {weeks[Math.floor((hoverPosition.x / svgRef.current.getBoundingClientRect().width) * weeks.length)]}
            </div>
          )}

          {/* Hover Line */}
          <div
            className="hover-line"
            style={{
              position: 'absolute',
              left: `${hoverPosition.x}px`,
              top: '0',
              bottom: '0',
              width: '1px',
              backgroundColor: 'black',
            }}
          ></div>
        </div>

        {/* X-axis with only two labels */}
        <div className="x-axis">
          <span className="x-axis-label">Sep 1, 2024</span>
          <span className="x-axis-label" style={{ marginLeft: 'auto' }}>Nov 1, 2024</span>
        </div>

      </div>
    </div>
  );
};

export default Graph;
