import React from 'react';

const Dropdown = () => {
  return (
    <div className="sideproject-image ui-snippet-wrapper ui-component-dropdown">
      <div className="ui-dropdown">
        <div class="ui-dropdown-label"><span>Account</span><div><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.37292 0.75H2.74609C1.64152 0.75 0.746094 1.64543 0.746094 2.75V9.25C0.746094 10.3546 1.64152 11.25 2.74609 11.25H9.25339C10.358 11.25 11.2534 10.3546 11.2534 9.25V8.625" stroke="black" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 6L11.2536 0.75M11.2536 0.75V5.125M11.2536 0.75H6.87561" stroke="black" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div></div>
        <div class="ui-dropdown-label"><span>Preferences</span></div>
        <div className="ui-dropdown-divider"></div>
        <div class="ui-dropdown-label"><span>Workspace settings</span></div>
        <div class="ui-dropdown-label"><span>Switch workspace</span></div>
        <div class="ui-dropdown-label"><span>Invite teammembers</span></div>
        <div className="ui-dropdown-divider"></div>
        <div class="ui-dropdown-label"><span>Update now</span></div>
        <div class="ui-dropdown-label"><span>Log out</span><div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 13H8H10C10.2761 13 10.5 12.7761 10.5 12.5V9.75C10.5 9.61193 10.6119 9.5 10.75 9.5H13.2929C13.7383 9.5 13.9614 8.96143 13.6464 8.64645L8.53033 3.53033C8.23744 3.23744 7.76256 3.23744 7.46967 3.53033L2.35355 8.64645C2.03857 8.96143 2.26165 9.5 2.70711 9.5H5.25C5.38807 9.5 5.5 9.61193 5.5 9.75V12.5C5.5 12.7761 5.72386 13 6 13Z" stroke="black" stroke-width="1.35" stroke-linecap="round"/>
        </svg>Q</div></div>
      </div>
    </div>
  );
};

export default Dropdown;
