import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import projectsData from '../projects/projects.json';

const ListLayout  = ({ heroImage }) => {
  const location = useLocation();
  const currentURL = location.pathname.toLowerCase();

  console.log(heroImage);

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  // Filter out the project that matches the current URL
  const filteredProjects = projects.filter((project) =>
    !currentURL.includes(project.title.toLowerCase().replace(/\s+/g, '-'))
  );

  return (
    <div>
      <div className="list-wrapper">
        {filteredProjects.map((project, index) => (
          <Link to={`/project/${project.title.toLowerCase().replace(/\s+/g, '-')}`}>
          <div className="list-item" key={index}>
            <div className="flex">
              <div className="left-side">
                  <h2>{project.title}</h2>
              </div>
              <div className="right-side">
                <p>{project.categories.join(', ')}</p>
                <p>{project.year}</p>
              </div>
            </div>
          </div>
        </Link>
        ))}
      </div>
    </div>
  );
};

export default ListLayout;
