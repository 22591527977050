import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import me from '../images/about/me.png';

const About = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
        window.scrollTo(0, 0);
        setIsFirstRender(false);
      }
  })
  return (
    <div>
      <div className="about-wrapper">


      <div className="flex">
        <div className="title-wrapper">
          <div className="left-side label-group-wrapper">
            <p className="tiny-label grey-text mono">
              Availability
            </p>
            <div className="label-group mono">
            <p className="tiny-label">
              Currently not available.
            </p>
            <p className="tiny-label">
              Working at Signifly.
            </p>
            <p className="tiny-label label-off">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="poke">
                <path d="M5 0H11V1H5V0Z" fill="#FF3D00"/>
                <path d="M4 2V1H5V2H4Z" fill="#FF3D00"/>
                <path d="M7 7H6V9H7V7Z" fill="#FF3D00"/>
                <path d="M9 7H10V9H9V7Z" fill="#FF3D00"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5V4H4V2H3V4H2V6H1V8H2V10H1V12H2V13H3V15H4V16H7V15H9V16H12V15H13V13H14V12H15V10H14V8H15V6H14V4H13V2H12V1H11V2H12V4H11V5H5ZM12 5H11V6H5V5H4V7H3V6H2V8H4V9H5V10H11V9H12V8H14V6H13V7H12V5ZM14 10V12H12V10H14ZM11 13H12V15H9V14H11V13ZM9 13V12H11V13H9ZM7 13H9V14H7V13ZM5 13V12H7V13H5ZM5 13H4V15H7V14H5V13ZM2 12H4V10H2V12Z" fill="#FF3D00"/>
              </svg>
              Sr. Designer.
            </p>
            </div>
          </div>
          <div className="right-side">
            <h1>
              About
            </h1>
          </div>
        </div>
        </div>

        <div className="bio-wrapper">
          <div className="left-side">
          </div>
          <div className="right-side">
            <p className="bio">
              I'm a multidisciplinary designer, specialized in creating digital products and experiences. I’ve worked with digital design for the past 10 years, and I’ve had the pleasure of working with some of the biggest companies and most ambitious startups in Scandinavia, designing products, apps and e-commerce.
            </p>
          </div>
        </div>


        <div className="info-wrapper">
          <div className="column">
            <p className="grey-text mono">A</p>
          </div>
          <div className="column main-group">
            <p className="grey-text mono">Services</p>
          </div>
          <div className="flex desk-flex">
            <div className="column main-group">
              <p className="grey-text mono">Digital Design</p>
              <div className="list">
                <p>Product design</p>
                <p>UI/UX</p>
                <p>Design systems</p>
                <p>Native Apps</p>
                <p>E-Commerce</p>
                <p>Websites</p>
              </div>
            </div>
            <div className="column last-column">
              <p className="grey-text mono">Brand Design</p>
              <div className="list">
                <p>Brand identities</p>
                <p>Iconography</p>
                <p>Data visualization</p>
                <p>Art Direction</p>
                <p>Concept development</p>
              </div>
            </div>
          </div>
        </div>

        <div className="info-wrapper">
          <div className="column">
            <p className="grey-text mono">B</p>
          </div>
          <div className="column main-group">
            <p className="grey-text mono">Clients</p>
          </div>
          <div className="column client-column">
              <p>Maersk</p>
              <p>Novo Nordisk</p>
              <p>Siemens</p>
              <p>7-Eleven</p>
              <p>Agency of Digitisation Denmark</p>
              <p>Ferm Living</p>
              <p>DR</p>
              <p>Bio Innovation Institute</p>
              <p>Tekla</p>
              <p>StormGeo</p>
              <p>Cecilie Bahnsen</p>
              <p>Nemlig.com</p>
              <p>Zaptec</p>
              <p>OOONO</p>
              <p>Leo Pharma</p>
              <p>Foreign Ministry of Denmark</p>
              <p>Basic Apparel</p>
              <p>Viking</p>
              <p>Auditdata</p>
              <p>Fertin Pharma</p>
              <p>Prescriba</p>
              <p>Snafu Records</p>
          </div>
          <div className="column last-column"></div>
        </div>


        <div className="info-wrapper">
          <div className="column">
            <p className="grey-text mono">C</p>
          </div>
          <div className="column main-group">
            <p className="grey-text mono">Recognition</p>
          </div>
          <div className="column column-wide">
              <p>Creative Circle Awards — x2</p>
              <p>Danish Digital Award — 2x Finalist Best App Design</p>
              <p>Digitaliseringsprisen — 2x</p>
              <p>Danish Design Award — x1</p>
              <p>German Design Award — x1</p>
              <p>Logo Lounge — x3 published</p>
          </div>
        </div>

        <div className="info-wrapper">
          <div className="column">
            <p className="grey-text mono">D</p>
          </div>
          <div className="column main-group">
            <p className="grey-text mono">Experience</p>
          </div>
          <div className="column column-wide">
              <p>2013 - Now: Freelance</p>
              <p>2017 - 2022: Sr. Designer @ Signifly</p>
              <p>2022 - 2023: Creative Director @ OOONO</p>
              <p>2023 - 2023: Independent, startup</p>
              <p>2023 - Now: Sr. Designer @ Signifly</p>
          </div>
        </div>


        <div className="info-wrapper">
          <div className="column">
            <p className="grey-text mono">E</p>
          </div>
          <div className="column main-group">
            <p className="grey-text mono">Toolstack</p>
          </div>
          <div className="column">
              <p>Figma</p>
              <p>Jitter</p>
              <p>Principle</p>
              <p>Photoshop</p>
              <p>Illustrator</p>
          </div>
          <div className="column last-column"></div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default About;
