import React, { useState } from 'react';

const Dates = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handlePreviousMonth = () => {
    const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
    setCurrentMonth(previousMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
    setCurrentMonth(nextMonth);
  };

  const handleDateClick = (day) => {
    const selectedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);

    if (!fromDate || (fromDate && toDate)) {
      setFromDate(selectedDate);
      setToDate(null);
    } else if (fromDate && !toDate && selectedDate > fromDate) {
      setToDate(selectedDate);
    } else {
      setFromDate(selectedDate);
      setToDate(null);
    }
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(currentMonth.getMonth(), currentMonth.getFullYear());
    const startDayOfWeek = (new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay() + 6) % 7;
    const days = [];
    let firstInRangeFound = false;
    let lastInRangeFound = false;

    for (let i = 0; i < startDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
      const isSelected = (fromDate && date.getTime() === fromDate.getTime()) ||
                         (toDate && date.getTime() === toDate.getTime());

      let additionalClass = '';
      const inRange = fromDate && toDate && date > fromDate && date < toDate;

      if (inRange && !firstInRangeFound) {
        additionalClass = 'first-in-range';
        firstInRangeFound = true;
      }

      // Apply last-in-range to the last day before the toDate
      if (inRange && !lastInRangeFound && day === daysInMonth) {
        additionalClass = 'last-in-range';
        lastInRangeFound = true;
      } else if (inRange && !lastInRangeFound && day + 1 <= daysInMonth) {
        const nextDayDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day + 1);
        if (nextDayDate >= toDate) {
          additionalClass = 'last-in-range';
          lastInRangeFound = true;
        }
      }

      days.push(
        <div
          key={day}
          className={`calendar-day ${isSelected ? 'selected-day' : ''} ${inRange ? 'in-range' : ''} ${additionalClass}`}
          onClick={() => handleDateClick(day)}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const formatMonthYear = (date) => {
    return `${date.toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase()}${date.toLocaleString('default', { month: 'long' }).slice(1)} ${date.getFullYear()}`;
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper">
    <div className="ui-component-dates-inner">
      <div className="ui-component-dates">
        <div className="calendar-header">
          <button className="month-nav" onClick={handlePreviousMonth}>
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 1.5L4 6L8.5 10.5" stroke="black"/>
            </svg>
          </button>
          <span className="month-year">{formatMonthYear(currentMonth)}</span>
          <button className="month-nav" onClick={handleNextMonth}>
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.5 1.5L9 6L4.5 10.5" stroke="black"/>
            </svg>
          </button>
        </div>
        <div className="calendar-days-of-week">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="day-of-week">{day}</div>
          ))}
        </div>
        <div className="calendar-days">
          {renderCalendarDays()}
        </div>
        <button className="calendar-button">Select dates</button>
      </div>
      </div>
    </div>
  );
};

export default Dates;
