import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';

import switchapp from '../images/projects/switch.png';
import lost from '../images/projects/lost.png';
import fight from '../images/projects/fight.png';
import hurricane from '../images/projects/hurricane.png';
import terrain from '../images/projects/terrain.png';

const Projects = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
        window.scrollTo(0, 0);
        setIsFirstRender(false);
      }
  })
  return (
    <div>
      <div className="side-flex">

        <div className="title-wrapper">
          <div className="left-side label-group-wrapper">
            <p className="grey-text large-body left-copy inc-width">
              Sideprojects related to design, development, film and music.
            </p>
          </div>
          <div className="right-side">
            <h1>
              Projects
            </h1>
          </div>
        </div>
      </div>

      <div>

      <div className="flex sideproject-wrapper">

        <div className="sideproject-inner-wrapper">
          <div className="sideproject-content">
            <p className="sideproject-name">Switch</p>
            <h2>A simple desktop app for switching songs in an instant.</h2>
            <div className="group-combine">
              <div className="sideproject-group">
                  <label>Status</label>
                  <div className="status-wrapper">
                  <span>In Development</span>
                  </div>
                </div>
                <div className="sideproject-group">
                  <label>Link</label>
                  <span>Coming soon</span>
                </div>
              </div>
            </div>
            <div className="sideproject-image">
              <img src={switchapp} alt=""/>
            </div>
          </div>
        </div>

        <div className="sideproject-inner-wrapper">
          <div className="sideproject-content">
            <p className="sideproject-name">Lost</p>
            <h2>Narrating the beautiful ordinary.</h2>
            <div className="group-combine">
            <div className="sideproject-group">
              <label>Status</label>
              <div className="status-wrapper">
                <div className="status live"></div>
              <span>Live</span>
              </div>
            </div>
            <div className="sideproject-group">
              <label>Link</label>
              <a target="_blank" href="https://lost.studio">lost.studio</a>
            </div>
          </div>
        </div>
            <div className="sideproject-image">
              <img src={lost} alt=""/>
            </div>
          </div>
        </div>

        <div className="sideproject-inner-wrapper">
          <div className="sideproject-content">
            <p className="sideproject-name">Fight Club: A Tribute Site</p>
            <h2>The ultimate tribute site to the greatest film ever made.</h2>
            <div className="group-combine">
            <div className="sideproject-group">
              <label>Status</label>
              <div className="status-wrapper">
              <span>In Development</span>
              </div>
            </div>
            <div className="sideproject-group">
              <label>Link</label>
              <span>Coming sooner or later</span>
            </div>
          </div>
          </div>
            <div className="sideproject-image">
              <img src={fight} alt=""/>
            </div>
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Hurricane Generator</p>
              <h2>A generator made for a client, to generate hurricanes as a graphic element.</h2>
              <div className="group-combine">
              <div className="sideproject-group">
                <label>Status</label>
                <div className="status-wrapper">
                  <div className="status live"></div>
                  <span>Live</span>
                </div>
              </div>
              <div className="sideproject-group">
                <label>Link</label>
                <a target="_blank" href="https://hurricane.martinballe.com/generator">hurricane.martinballe.com</a>
              </div>
            </div>
            </div>
              <div className="sideproject-image">
                <img src={hurricane} alt=""/>
              </div>
            </div>

            <div className="sideproject-inner-wrapper no-border">
              <div className="sideproject-content">
                <p className="sideproject-name">Terrain Generator</p>
                <h2>A generator made for a client, to generate data based terrains as a graphic element.</h2>
                <div className="group-combine">
                <div className="sideproject-group">
                  <label>Status</label>
                  <div className="status-wrapper">
                    <div className="status live"></div>
                  <span>Live</span>
                  </div>
                </div>
                <div className="sideproject-group">
                  <label>Link</label>
                  <a target="_blank" href="https://terrain.martinballe.com/generator">terrain.martinballe.com</a>
                </div>
              </div>
              </div>
                <div className="sideproject-image">
                  <img src={terrain} alt=""/>
                </div>
              </div>

      <Footer />
    </div>
  );
};

export default Projects;
