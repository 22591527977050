import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Tabs from './snippets/Tabs';
import Dropdown from './snippets/Dropdown';
import Calendar from './snippets/Calendar';
import Checkbox from './snippets/Checkbox';
import Flashlight from './snippets/Flashlight';
import DatesVertical from './snippets/DatesVertical';
import Dates from './snippets/Dates';
import Input from './snippets/Input';
import Button from './snippets/Button';
import Graph from './snippets/Graph';
import Dock from './snippets/Dock';

const Snippets = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      window.scrollTo(0, 0);
      setIsFirstRender(false);
    }
  }, [isFirstRender]);

  return (
    <div>
      <div className="side-flex">
        <div className="title-wrapper">
          <div className="left-side label-group-wrapper">
            <p className="grey-text large-body left-copy inc-width">
              A small library of fun and interactive components.
            </p>
          </div>
          <div className="right-side">
            <h1>
              UI Snippets
            </h1>
          </div>
        </div>
      </div>

      <div>
        <div className="sideproject-wrapper">

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Tabs</p>
              <h2>A dynamic tab selector</h2>
            </div>
            <Tabs />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Dropdown</p>
              <h2>A simple and elegant dropdown with hover functionality</h2>
            </div>
            <Dropdown />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Calendar</p>
              <h2>Snappy event handling in calendar</h2>
            </div>
            <Calendar />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Dates</p>
              <h2>A tiny, neat horizontal calendar</h2>
            </div>
            <DatesVertical />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Dock</p>
              <h2>A smooth scaling effect with MacOS inspired dock</h2>
            </div>
            <Dock />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Graph</p>
              <h2>A simple interactive graph component</h2>
            </div>
            <Graph />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Dates</p>
              <h2>A simple calendar component</h2>
            </div>
            <Dates />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Checkbox</p>
              <h2>An animated checkbox with a colorful surprise</h2>
            </div>
            <Checkbox />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Input</p>
              <h2>An input fields that shrinks the placeholder</h2>
            </div>
            <Input />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Flashlight</p>
              <h2>A cursor based flashlight to reveal stuff</h2>
            </div>
            <Flashlight />
          </div>

          <div className="sideproject-inner-wrapper">
            <div className="sideproject-content">
              <p className="sideproject-name">Button</p>
              <h2>Minor arrow detail on hover</h2>
            </div>
            <Button />
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Snippets;
