import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from './pages/Home';
import About from './pages/About';
import Play from './pages/Play';
import Work from './pages/Work';
import Snippets from './pages/Snippets';
import Projects from './pages/Projects';
import Error from './pages/404';
import Project from './pages/projects/Project.js';
import CustomCursor from './components/CustomCursor';
import CommandMenu from './components/Search';
import Navigation from './components/Navigation';
import projectsData from './projects/projects.json';
import './App.css';

const App = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showCopyToast, setShowCopyToast] = useState(false);
  const [projectColor, setProjectColor] = useState('');

  const handleKeyPress = (e) => {
    if (e.metaKey && e.key === 'k') {
      e.preventDefault();
      setMenuIsOpen(!menuIsOpen);
      e.stopPropagation();
    }
  };

  const toggleMenu = () => {
    setTimeout(() => setMenuIsOpen(!menuIsOpen), 100);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Your scroll handling logic, if any
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const pagesData = [
    { title: 'Work', link: '/work' },
    { title: 'About', link: '/about' },
    { title: 'Play', link: '/play' },
    { title: 'UI Snippets', link: '/snippets' },
    { title: 'Projects', link: '/projects' },
  ];

  const hideCopyToast = () => {
    setShowCopyToast(false);
  };

  const getProjectColor = (pathname) => {
    const project = projectsData.find(project => project.link === pathname);
    return project ? project.color : '';
  };

  const aboutPageColor = "#FF5733"; // Example color for About page

  const Wrapper = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === '/about') {
        setProjectColor(aboutPageColor);
      } else if (location.pathname.startsWith('/project')) {
        const color = getProjectColor(location.pathname);
        setProjectColor(color);
      } else {
        setProjectColor('');
      }
    }, [location]);

    return (
      <div className="App">
        <Navigation aboutPageColor={aboutPageColor} projectColor={projectColor} toggleMenu={toggleMenu}/>
        <div className="page-wrapper">
          <TransitionGroup>
            <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
              <Routes location={location}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/play" element={<Play />} />
                <Route path="/work" element={<Work />} />
                <Route path="/snippets" element={<Snippets />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/project/:projectSlug" element={<Project />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <CommandMenu isOpen={menuIsOpen} onClose={toggleMenu} pages={pagesData} projects={projectsData} />
      </div>
    );
  };

  return (
    <Router>
      <Wrapper />
    </Router>
  );
};

export default App;
