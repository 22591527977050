import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Clock from './Clock';

const Navigation = ({ aboutPageColor, projectColor, toggleMenu }) => {
  const location = useLocation();
  let navColor = '';

  if (location.pathname === '/about') {
    navColor = aboutPageColor;
  } else if (location.pathname.startsWith('/project')) {
    navColor = projectColor;
  }

  return (
    <nav className="navigation" style={{ color: navColor }}>
      <div className="nav-wrapper-left">
        <div className="nav-wrapper-left-inner">
          <Link to="/">
            <span>Martin Balle</span>
          </Link>
          <Clock />
        </div>
      </div>
      <div className="nav-wrapper-right">
        <div className="nav-right-zero"></div>
        <div className="nav-right-one">
          <div className="links">
            <Link to="/work">Work</Link>
            <Link to="/about">About</Link>
            <Link to="/play">Play</Link>
            <Link to="/snippets" className="mobile-hide-link">UI Snippets</Link>
            <Link to="/projects" className="hide-link">Projects</Link>
            <div className="command-trigger" onClick={toggleMenu}>⌘K</div>
          </div>
        </div>
        <div className="nav-right-two">
          <div className="links">
            <Link to="/projects">
              <div className="lost">Projects</div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
