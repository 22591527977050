import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import projectsData from '../../projects/projects.json';
import ListLayout from '../../components/ListLayout';
import Footer from '../../components/Footer';

import hero0 from '../../images/ot_search.png';
import hero1 from '../../images/boxes.jpg';
import hero2 from '../../images/lp/lp-signifly.jpg';
import hero4 from '../../images/lp/lp-card-cp.jpg';
import hero5 from '../../images/lp/lp-card-brainy.jpg';
import hero6 from '../../images/lp/lp-card-blurry.jpg';
import hero7 from '../../images/lp/lp-card-hcc.jpg';
import hero8 from '../../images/lp/lp-card-ferm.jpg';
import hero9 from '../../images/lp/lp-card-zaptec.jpg';
import hero10 from '../../images/lp/lp-card-ooono.jpg';
import hero11 from '../../images/lp/lp-card-tekla.jpg';
import hero12 from '../../images/lp/lp-card-spotifly.jpg';
import hero13 from '../../images/lp/lp-card-ddl.jpg';
import hero14 from '../../images/lp/lp-card-lost.jpg';
import hero15 from '../../images/lp/storm_square.jpg';
import hero16 from '../../images/lp/lp-card-7.jpg';
import hero17 from '../../images/lp/lp-card-losers.jpg';
import hero18 from '../../images/lp/lp-yolk-card.jpg';

const Project = () => {
  const { projectSlug } = useParams(); // Get the project slug from the URL parameter

  const [project, setProject] = useState(null);
  const [nextProjectIndex, setNextProjectIndex] = useState(null);
  const [nextProjectHeroImage, setNextProjectHeroImage] = useState(null);
    const [isFirstRender, setIsFirstRender] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (isFirstRender) {
        window.scrollTo(0, 0);
        setIsFirstRender(false);
      }

    // Find the project that matches the slug
    const selectedProject = projectsData.find(
      (project) =>
        project.title.toLowerCase().trim().replace(/\s+/g, '-') === projectSlug
    );

    if (selectedProject) {
      setProject(selectedProject);

      // Find the index of the current project
      const currentIndex = projectsData.findIndex(
        (project) => project.title === selectedProject.title
      );

      // Calculate the index of the next project
      const nextIndex = (currentIndex + 1) % projectsData.length;

      setNextProjectIndex(nextIndex);

      const nextProject = projectsData[nextIndex];
      setNextProjectHeroImage(nextProject.heroImage);
    } else {
      // Handle the case when the project is not found
      // You can display an error message or redirect to a 404 page
    }
  }, [projectSlug]);

  useEffect(() => {
    // Use the location object to detect changes in the URL
    if (!isFirstRender) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  if (!project) {
    // Handle the case when the project is not found
    // You can display an error message or redirect to a 404 page
    return (
      <div>
        <p>Project not found.</p>
      </div>
    );
  }

  const nextProject = projectsData[nextProjectIndex];

  let heroImage = null;
  if (nextProject) {
    if (nextProject.title === 'Eupry') {
      heroImage = hero1;
    } else if (nextProject.title === 'Signifly') {
      heroImage = hero2;
    } else if (nextProject.title === 'Corona Passport') {
      heroImage = hero4;
    } else if (nextProject.title === 'Brainy') {
      heroImage = hero5;
    } else if (nextProject.title === 'Blurry') {
      heroImage = hero6;
    } else if (nextProject.title === 'Healthcare Card') {
      heroImage = hero7;
    } else if (nextProject.title === 'Ferm Living') {
      heroImage = hero8;
    } else if (nextProject.title === 'OOONO') {
      heroImage = hero10;
    } else if (nextProject.title === 'Tekla') {
      heroImage = hero11;
    } else if (nextProject.title === 'Zaptec') {
      heroImage = hero9;
    } else if (nextProject.title === 'Switch') {
      heroImage = hero12;
    } else if (nextProject.title === 'Digital Drivers License') {
      heroImage = hero13;
    } else if (nextProject.title === 'Lost') {
      heroImage = hero14;
    } else if (nextProject.title === 'StormGeo') {
      heroImage = hero15;
    } else if (nextProject.title === '7-Eleven') {
      heroImage = hero16;
    } else if (nextProject.title === 'Losers') {
      heroImage = hero17;
    } else if (nextProject.title === 'Yolk') {
      heroImage = hero18;
    } else if (nextProject.title === 'Original Talks') {
      heroImage = hero0;
    }
  }

  return (
    <div>
      <div style={{ color: project.color }}>
        <div>
        <div style={{ backgroundColor: project.backgroundColor }} className="case-hero flex">
          <div className="title-wrapper">
            <div className="left-side label-group-wrapper project-color go-back">
              <Link to="/work" className="grey-text left-copy tiny-label mono">
                Go back
              </Link>
            </div>
            <div className="right-side">
              <h1>{project.title}</h1>
            </div>
          </div>
        <div className="flex project-info">
          <div className="left-side"></div>
          <div className="right-side flex space">
            <div className="narrow">
              <p>{project.description}</p>
            </div>
            <div className="tight">
              <p className="narrow grey-text mono marg-bot-4">Deliverables</p>
              <p>{project.categories}</p>
              <div className="year-wrapper">
              <p className="narrow grey-text mono marg-bot-4">Year</p>
              <p>{project.year}</p>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        <div className="case-image-wrapper">
        {project.media.map((item, index) => (
          item.type === 'image' ? (
            <img key={index} src={`/${item.src}`} alt={`Media Item ${index}`} className={item.className}/>
          ) : (
            <video key={index} autoPlay muted playsInline loop class={item.className}>
              <source src={`/${item.src}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )
        ))}
        </div>
      </div>
      <div className="flex next-project-wrapper"  style={{ color: project.bottomColor }}>
        <div className="left-side"></div>
        <div className="right-side">
          {nextProject && (
          <div className="next-project-link">
            <Link to={`/project/${nextProject.title.toLowerCase().trim().replace(/\s+/g, '-')}`}>
              <span>Up next:</span>
              <br className="line-break"/>
              <span>{nextProject.title}</span>
                <img src={heroImage} alt=""/>
            </Link>
          </div>
        )}
        </div>
      </div>
      <div className="more-work" style={{ color: project.bottomColor }}>
        <span style={{ color: project.bottomColor }}>All work</span>
        <ListLayout />
      </div>
      <div className="black"  style={{ color: project.bottomColor }}>
      <Footer />
      </div>
      </div>
  );
};

export default Project;
