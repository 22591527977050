import React, { useState, useEffect } from 'react';

const DatesVertical = () => {
  const [hoveredDate, setHoveredDate] = useState(null);
  const [linePosition, setLinePosition] = useState(null);
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');
  const [daysInMonth, setDaysInMonth] = useState(28); // Default for February
  const [currentDay, setCurrentDay] = useState(1); // Track the current day
  const [currentDayPosition, setCurrentDayPosition] = useState(0); // Position for the current day

  const dates = [1, 7, 14, 21, 28]; // Fixed date markers

  // Helper to get days in a month
  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Helper to format month name
  const getShortMonthName = (date) => {
    return date.toLocaleString('default', { month: 'short' });
  };

  useEffect(() => {
    const today = new Date();
    const month = getShortMonthName(today);
    const year = today.getFullYear();
    const daysInCurrentMonth = getDaysInMonth(today.getMonth(), year);

    setCurrentMonth(month);
    setCurrentYear(year);
    setDaysInMonth(daysInCurrentMonth);
    setCurrentDay(today.getDate()); // Set the current day

    // Calculate the current day's position based on the daysInMonth
    const calendarWidth = document.querySelector('.ui-component-dates-vertical').getBoundingClientRect().width;
    const segmentWidth = calendarWidth / (dates.length - 1); // Divide the calendar into 4 segments

    let dayPosition;
    if (today.getDate() <= 7) {
      dayPosition = ((today.getDate() - 1) / 7) * segmentWidth;
    } else if (today.getDate() <= 14) {
      dayPosition = segmentWidth + ((today.getDate() - 8) / 7) * segmentWidth;
    } else if (today.getDate() <= 21) {
      dayPosition = 2 * segmentWidth + ((today.getDate() - 15) / 7) * segmentWidth;
    } else if (today.getDate() <= 28) {
      dayPosition = 3 * segmentWidth + ((today.getDate() - 22) / 7) * segmentWidth;
    } else {
      // Beyond the 28th for months with 29-31 days
      dayPosition = 4 * segmentWidth + ((today.getDate() - 29) / (daysInCurrentMonth - 28)) * segmentWidth;
    }

    setCurrentDayPosition(dayPosition);
  }, [dates.length]);

  const handleMouseMove = (e) => {
    const calendarRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - calendarRect.left;
    const calendarWidth = calendarRect.width;

    // Clamp the cursor position within the calendar width plus extra padding
    const clampedOffsetX = Math.max(0, Math.min(offsetX, calendarWidth + 40));

    const segmentWidth = calendarWidth / (dates.length - 1); // 4 segments

    let day;
    if (clampedOffsetX < segmentWidth) {
      // Segment 1: Days 1-7
      day = Math.floor((clampedOffsetX / segmentWidth) * 7) + 1;
    } else if (clampedOffsetX < segmentWidth * 2) {
      // Segment 2: Days 8-14
      day = Math.floor(((clampedOffsetX - segmentWidth) / segmentWidth) * 7) + 8;
    } else if (clampedOffsetX < segmentWidth * 3) {
      // Segment 3: Days 15-21
      day = Math.floor(((clampedOffsetX - 2 * segmentWidth) / segmentWidth) * 7) + 15;
    } else if (clampedOffsetX < segmentWidth * 4) {
      // Segment 4: Days 22-28
      day = Math.floor(((clampedOffsetX - 3 * segmentWidth) / segmentWidth) * 7) + 22;
    } else {
      // Segment 5: Days 29-End (handles months with 29, 30, or 31 days)
      const daysBeyond28 = daysInMonth - 28; // 29, 30, 31 depending on the month
      const maxExtraSegment = Math.min(daysBeyond28, 3); // To ensure we don't exceed
      day = Math.floor(((clampedOffsetX - 4 * segmentWidth) / (calendarWidth - 4 * segmentWidth)) * maxExtraSegment) + 29;
    }

    // Ensure the day does not exceed the number of days in the month
    day = Math.min(day, daysInMonth);

    setHoveredDate(day);
    setLinePosition(clampedOffsetX);
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper">
      <div
        className="ui-component-dates-vertical"
        onMouseMove={handleMouseMove}
        style={{ paddingRight: '40px', position: 'relative' }} // Relative positioning for absolute children
      >
        {dates.map((date, index) => (
          <div
            className="date-vertical"
            key={index}
            style={{
              position: 'absolute',
              left: `${(index / (dates.length - 1)) * 100}%`,
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
          >
            <span>{date}</span>
          </div>
        ))}

        {linePosition !== null && hoveredDate <= daysInMonth && (
          <div
            className="hover-line"
            style={{
              left: `${linePosition}px`,
            }}
          >
            <div
              className="specific-date"
            >
              {currentMonth} {hoveredDate}
            </div>
          </div>
        )}

        {/* Display the current day at the correct position */}
        <div
          className="hover-line absolute-line"
          style={{
            left: `${currentDayPosition}px`, // Use the calculated position for the current day
          }}
        >
          <div
            className="specific-date"
          >
            {currentMonth} {currentDay} {/* Display current day */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatesVertical;
