import React, { useState } from 'react';

const Checkbox = () => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper">
    <div className="ui-component-checkbox">
      <label className="checkbox-wrapper">
        <input
          type="checkbox"
          className="checkbox-input"
          onChange={handleCheckboxChange}
          checked={checked}
        />
        <span className="checkbox-custom"></span>
        {checked && (
          <div className="circle-container">
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={index} className={`circle circle-${index + 1}`}></div>
            ))}
          </div>
        )}
      </label>
    </div>
    </div>
  );
};

export default Checkbox;
