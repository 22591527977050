import React, { useState, useRef } from 'react';
import icon1 from './images/lost-icon.png'; // Importing images from the src folder
import icon2 from './images/yolk-icon.png';
import icon3 from './images/switch-icon.png';
import icon4 from './images/brainy-icon.png';
import icon5 from './images/beatboard-icon.png';

const Dock = () => {
  const iconCount = 5; // Number of icons
  const dockRef = useRef(null); // Reference to the dock element
  const [hoverX, setHoverX] = useState(null); // Mouse X position in the dock

  // Array of image URLs for each icon
  const iconImages = [icon1, icon2, icon3, icon4, icon5];

  // Function to calculate scale for each icon based on the distance from the cursor
  const calculateScale = (iconX, hoverX) => {
    const distance = Math.abs(hoverX - iconX); // Distance between icon center and mouse
    const influenceRange = 600; // How far the influence of the mouse reaches
    const maxScale = 1.15; // Reduced maximum scale for the hovered icon

    // Calculate the scale based on distance, the closer the icon, the bigger it gets
    return Math.max(1, maxScale - distance / influenceRange);
  };

  // Handle mouse movement within the dock
  const handleMouseMove = (e) => {
    const dockRect = dockRef.current.getBoundingClientRect(); // Get the dock's bounding box
    setHoverX(e.clientX - dockRect.left); // Set the mouse X relative to the dock
  };

  // Reset when the mouse leaves the dock
  const handleMouseLeave = () => {
    setHoverX(null); // Reset hover position when mouse leaves
  };

  return (
    <div className="sideproject-image ui-snippet-wrapper">
      <div className="ui-component-dock">
        <div
          className="ui-dock"
          ref={dockRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          {iconImages.map((imageSrc, index) => {
            const iconX = index * 80 + 40; // Calculate the center X of each icon

            return (
              <div
                key={index}
                className="ui-app-icon"
                style={{
                  transformOrigin: 'bottom', // Scale from the bottom upwards
                  transform: hoverX !== null ? `scale(${calculateScale(iconX, hoverX)})` : 'scale(1)',
                  transition: 'transform 0.05s linear', // Smoother scaling
                }}
              >
                <img
                  src={imageSrc}
                  alt={`App Icon ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensure the image covers the whole div
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dock;
