import React, { useState, useRef } from 'react';

const Calendar = () => {
  const [eventTitle, setEventTitle] = useState('');
  const [eventPosition, setEventPosition] = useState({ top: 0, height: 0 });
  const [eventTimeRange, setEventTimeRange] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [cursorY, setCursorY] = useState(null); // Cursor Y position for the hover-line
  const calendarRef = useRef(null);

  const totalCalendarHeight = 200; // Total height of the calendar
  const intervalCount = 12; // 12 intervals (each 15 minutes)
  const slotHeight = totalCalendarHeight / intervalCount; // Each 15-minute interval is ~16.66px

  const timeSlots = [
    '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM',
    '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
    '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
    '12:00 PM'
  ];

  const snapToSlot = (y) => {
    const snappedPosition = Math.floor(y / slotHeight) * slotHeight;
    return snappedPosition;
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);

    const calendarRect = calendarRef.current.getBoundingClientRect();
    const initialY = Math.max(0, Math.min(e.clientY - calendarRect.top, calendarRect.height));
    const snappedInitialY = snapToSlot(initialY);
    const initialSlotIndex = Math.floor(snappedInitialY / slotHeight);

    const startTime = timeSlots[initialSlotIndex] || timeSlots[0];
    const endTime = timeSlots[initialSlotIndex + 1] || timeSlots[1];

    setEventPosition({ top: snappedInitialY, height: slotHeight });
    setEventTimeRange(`${startTime} - ${endTime}`);

    const handleMouseMove = (e) => {
      const currentY = Math.max(0, Math.min(e.clientY - calendarRect.top, calendarRect.height));
      const snappedCurrentY = snapToSlot(currentY);

      const draggingUp = snappedCurrentY < snappedInitialY;

      // Snap to the nearest 15-minute interval
      let snappedHeight = Math.abs(snappedCurrentY - snappedInitialY) + slotHeight;

      let newTop = draggingUp
        ? Math.max(snappedInitialY - snappedHeight + slotHeight, 0)
        : snappedInitialY;

      const newSlotIndex = Math.floor(snappedCurrentY / slotHeight);
      const startSlot = draggingUp ? newSlotIndex : initialSlotIndex;
      const endSlot = draggingUp ? initialSlotIndex : newSlotIndex;

      const updatedStartTime = timeSlots[startSlot] || timeSlots[0];
      const updatedEndTime = timeSlots[endSlot + 1] || timeSlots[timeSlots.length - 1];

      // Prevent dragging beyond the calendar bottom
      snappedHeight = Math.min(snappedHeight, totalCalendarHeight - newTop);

      setEventTimeRange(`${updatedStartTime} - ${updatedEndTime}`);
      setEventPosition({ top: newTop, height: snappedHeight });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const calendarRect = calendarRef.current.getBoundingClientRect();
    const boundedY = Math.max(0, Math.min(e.clientY - calendarRect.top, calendarRect.height));
    setCursorY(boundedY);
  };

  const getEventPadding = () => {
    return eventPosition.height >= 33.33 ? '12px' : '0';
  };

  return (
    <div
      className="sideproject-image ui-snippet-wrapper ui-calendar-outer"
      onMouseMove={handleMouseMove}
    >
      <div className="timestamps">
        <div className="ui-calendar-time-slot">9 AM</div>
        <div className="ui-calendar-time-slot">10 AM</div>
        <div className="ui-calendar-time-slot">11 AM</div>
        <div className="ui-calendar-time-slot">12 PM</div>
      </div>
      <div className="ui-component-calendar"
        ref={calendarRef}
        onMouseDown={handleMouseDown}
        style={{ height: `${totalCalendarHeight}px`, position: 'relative' }}
      >
        {!isDragging && cursorY !== null && (
          <div
            className="cursor-line"
            style={{ top: `${cursorY}px`, position: 'absolute', width: '100%' }}
          ></div>
        )}

        {eventPosition.height > 0 && (
          <div
            className="ui-calendar-event"
            style={{
              top: `${eventPosition.top}px`,
              height: `${eventPosition.height}px`,
              paddingTop: getEventPadding(),
            }}
          >
            <span
              type="text"
              className="ui-calendar-event-title"
            >{eventTimeRange}
            </span>
            <label>Event title</label>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
