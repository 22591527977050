import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';

import psyduck from '../images/play/psyduck.png';
import fingerprint from '../images/play/fingerprint.png';
import barcode from '../images/play/barcode.png';
import tools from '../images/play/tools.png';
import circle from '../images/play/circle.png';
import knob from '../images/play/knob.png';
import wheel from '../images/play/wheel.png';
import peek from '../images/play/no-peeking.png';
import update from '../images/play/update.png';
import logo1 from '../images/play/logo_1.png';
import dropdown from '../images/play/dropdown.png';
import sidebar from '../images/play/sidebar.png';
import guide from '../images/play/guide.png';
import command from '../images/play/command.png';
import card from '../images/play/card.png';
import newwindow from '../images/play/newwindow.png';
import threefourteen from '../images/play/314.png';
import duplicate from '../images/play/duplicate.png';
import pattern from '../images/play/pattern.png';
import invite from '../images/play/invite.png';
import keys from '../images/play/keys.png';
import squid from '../images/play/squid.png';
import kvik from '../images/play/kvik.png';
import spotifly from '../images/play/spotifly.png';
import bg from '../images/play/bg.png';
import a from '../images/play/a.png';
import yolk from '../images/play/yolk.png';
import tinydrawer from '../images/play/tiny-drawer.png';
import biggerdrawer from '../images/play/bigger-drawer.png';
import shelle from '../images/play/shell-e.png';
import notifications from '../images/play/notifications.png';
import clients from '../images/play/clients.png';
import compensation from '../images/play/compensation.png';
import data from '../images/play/data.png';
import calendar from '../images/play/calendar.png';
import degrees from '../images/play/degrees.png';
import degrees2 from '../images/play/degrees-2.png';
import globe from '../images/play/globe.png';
import waves from '../images/play/waves.png';
import data1 from '../images/play/data-1.png';
import data2 from '../images/play/data-2.png';
import data3 from '../images/play/data-3.png';
import hurricane from '../images/play/hurricane.png';
import wayfinding from '../images/play/wayfinding.png';
import donaldduck from '../images/play/donald-duck.png';

import dragger from '../images/play/dragger.png';
import calendar2 from '../images/play/calendar_2.png';
import onboarding from '../images/play/onboarding.png';
import filter from '../images/play/filter.png';
import dropdown2 from '../images/play/dropdown_2.png';
import audio from '../images/play/audio.png';
import zaptec from '../images/play/zaptec.png';
import blur from '../images/play/blur.png';
import popup from '../images/play/popup.png';
import filter2 from '../images/play/filter2.png';
import stats from '../images/play/stats.png';

const Play = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
        window.scrollTo(0, 0);
        setIsFirstRender(false);
      }
  })
  return (
    <div>
      <div className="flex">

        <div className="title-wrapper">
          <div className="left-side label-group-wrapper">
            <p className="grey-text large-body left-copy">
              All work and no play makes Jack a dull boy.
            </p>
          </div>
          <div className="right-side">
            <h1>
              Play
            </h1>
          </div>
        </div>
      </div>

      <div className="grid-container">

      <div className="square">
        <div className="square-visual">
          <img src={clients} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Clients</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={degrees} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Degrees</p>
          <p>Experimental UI</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={data} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Data</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={globe} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Globe</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={calendar} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Calendar</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={biggerdrawer} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Bigger Drawer</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={degrees2} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Degrees</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={data1} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Data Visualization #1</p>
          <p>Graphics</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={data2} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Data Visualization #2</p>
          <p>Graphics</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={data3} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Data Visualization #3</p>
          <p>Graphics</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={audio} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Audio Player</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={command} alt="" />
        </div>
        <div className="square-text">
          <p>Command</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={filter} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Filter</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={dropdown2} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Dropdown</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={zaptec} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Zaptec</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={onboarding} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Onboarding</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={calendar2} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Calendar Empty State</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={wayfinding} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Wayfinding</p>
          <p>Misc</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={filter2} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Filter</p>
          <p>UI Snippet</p>
        </div>
      </div>

        <div className="square">
          <div className="square-visual">
            <img src={donaldduck} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Donald Duck</p>
            <p>Tattoo</p>
          </div>
        </div>

          <div className="square">
            <div className="square-visual">
              <img src={stats} alt="" className="invert"/>
            </div>
            <div className="square-text">
              <p>Stats</p>
              <p>UI Snippet</p>
            </div>
          </div>

            <div className="square">
              <div className="square-visual">
                <img src={psyduck} alt="" className="invert"/>
              </div>
              <div className="square-text">
                <p>Psyduck</p>
                <p>Tattoo</p>
              </div>
            </div>

      <div className="square">
        <div className="square-visual">
          <img src={notifications} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Notifications</p>
          <p>UI Snippet</p>
        </div>
      </div>

      <div className="square">
        <div className="square-visual">
          <img src={dropdown} alt="" />
        </div>
        <div className="square-text">
          <p>Dropdown</p>
          <p>UI Snippet</p>
        </div>
      </div>

          <div className="square">
            <div className="square-visual">
              <img src={blur} alt="" className="invert"/>
            </div>
            <div className="square-text">
              <p>Blur</p>
              <p>ID asset</p>
            </div>
          </div>

      <div className="square">
        <div className="square-visual">
          <img src={yolk} alt="" className="invert"/>
        </div>
        <div className="square-text">
          <p>Yolk</p>
          <p>Logo</p>
        </div>
      </div>

        <div className="square">
          <div className="square-visual">
            <img src={fingerprint} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Fingerprint</p>
            <p>Icon</p>
          </div>
        </div>

          <div className="square">
            <div className="square-visual">
              <img src={popup} alt="" className="invert"/>
            </div>
            <div className="square-text">
              <p>Popup</p>
              <p>UI Snippet</p>
            </div>
          </div>

        <div className="square">
          <div className="square-visual">
            <img src={barcode} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Barcode</p>
            <p>Icon</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={sidebar} alt="" />
          </div>
          <div className="square-text">
            <p>Sidebar</p>
            <p>UI Snippet</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={waves} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Waves</p>
            <p>Graphic Element</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={hurricane} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Hurricane</p>
            <p>Graphic Element</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={shelle} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>E</p>
            <p>Scrapped Logo</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={tools} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Tools</p>
            <p>Icon</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={circle} alt="" />
          </div>
          <div className="square-text">
            <p>Painted Sun in Abstract</p>
            <p>Misc</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={update} alt="" />
          </div>
          <div className="square-text">
            <p>New update</p>
            <p>UI snippet</p>
          </div>
        </div>


        <div className="square">
          <div className="square-visual">
            <img src={squid} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Logo</p>
            <p>Work</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={tinydrawer} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Tiny Drawer</p>
            <p>UI Snippet</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={knob} alt="" />
          </div>
          <div className="square-text">
            <p>Knob</p>
            <p>Misc</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={logo1} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Scrapped logo</p>
            <p>Archive</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={spotifly} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Logo</p>
            <p>Work</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={newwindow} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>New window</p>
            <p>Icon</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={guide} alt="" />
          </div>
          <div className="square-text">
            <p>Guide</p>
            <p>UI Snippet</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={wheel} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Wheel</p>
            <p>Misc</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={duplicate} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Duplicate</p>
            <p>Icon</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={keys} alt="" />
          </div>
          <div className="square-text">
            <p>Keys</p>
            <p>Misc</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={pattern} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Pattern</p>
            <p>Work</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={kvik} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Scrapped Logo</p>
            <p>Archive</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={peek} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>No peeking</p>
            <p>Icon</p>
          </div>
        </div>

        <div className="square">
          <div className="square-visual">
            <img src={a} alt="" className="invert"/>
          </div>
          <div className="square-text">
            <p>Logo</p>
            <p>Misc</p>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  );
};

export default Play;
